import React from "react"
import { useLocation } from "@reach/router"
import Hero from "../Hero"
import HeroImg from "../../images/hero_sustainable_tourism.jpg"
import HeroPhilhost from "../../images/hero_envision.jpg"
import HeroHot from "../../images/hero_hot.jpg"
import HeroEnvision from "../../images/hero_philhost.jpg"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import styled from "styled-components"
import LinedText from "../LinedText"

const TourismHero = ({ title, events }) => {
  const { pathname } = useLocation()
  let heroImg = HeroImg
  if (events === "philhost") {
    heroImg = HeroPhilhost
  } else if (events === "hotcoalition") {
    heroImg = HeroHot
  } else if (events === "envision") {
    heroImg = HeroEnvision
  }
  return (
    <Hero img={heroImg} page="inner">
      <Container>
        <Row>
          <Col col={10} xl={6}>
            {(pathname === "/sustainable-tourism" ||
              pathname === "/sustainable-tourism/") && (
              <TitleStyled size="d4" lg="d1">
                Sustainable&nbsp;
                <LinedText title="Tourism"></LinedText>
              </TitleStyled>
            )}

            {(pathname === "/philhost" || pathname === "/philhost/") && (
              <TitleStyled size="d4" lg="d1">
                <LinedText title="PhilHost"></LinedText>
              </TitleStyled>
            )}

            {(pathname === "/hotcoalition" ||
              pathname === "/hotcoalition/") && (
              <TitleStyled size="d4" lg="d1">
                HOT&nbsp;
                <LinedText title="Coalition"></LinedText>
              </TitleStyled>
            )}
            {(pathname === "/envision" || pathname === "/envision/") && (
              <TitleStyled size="d4" lg="d1">
                <LinedText title="ENVision"></LinedText>
              </TitleStyled>
            )}
          </Col>
        </Row>
      </Container>
    </Hero>
  )
}

const TitleStyled = styled(Title)`
  line-height: 1;
`

export default TourismHero
