import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import { FADEINUP } from "../../utils/animation"
import Title from "../Title"
import Section from "../Section"
import LineVector from "../LineVector"
import Container from "../Container"
import ArrowCta from "../ArrowCta"
import Row from "../Row"
import Col from "../Col"

const TourismLists = () => {
  const listTourism = useStaticQuery(graphql`
    query allTourismJsonQuery {
      allTourismJson {
        nodes {
          id
          tourism_id
          name
          url
          img {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  `)
  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <Title size="1" lg="d2" mb={6} mbLg={15}>
              Events
            </Title>
          </Col>
        </Row>
        <Row>
          {listTourism.allTourismJson.nodes
            .filter(n => n.tourism_id >= 1 && n.tourism_id <= 3)
            .map((n, idx) => (
              <ColBorder lg={4} key={idx}>
                <Wrapper>
                  <P>{n.name}</P>
                  <motion.div variants={FADEINUP}>
                    <LineVector height={6} width={154} />
                  </motion.div>
                </Wrapper>
                <CustomGatsbyImage mb={5} image={getImage(n.img)} alt="cover" />
                <ArrowCta title="View Event" to={n.url} />
              </ColBorder>
            ))}
        </Row>
      </Container>
    </Section>
  )
}

const ColBorder = styled(Col)`
  padding-top: 24px;
  padding-bottom: 24px;
`

const CustomGatsbyImage = styled(GatsbyImage)`
  margin-bottom: 20px;
`

const P = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 0;
`

const Wrapper = styled.div`
  margin-bottom: 20px;
`

export default TourismLists
