import React from "react"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import TourismHero from "../components/sustainable_tourism/TourismHero"
import TourismLists from "../components/sustainable_tourism/TourismLists"
import ContactLead from "../components/ContactLead"

const SustainableTourism = () => {
  return (
    <>
      <Seo title="Sustainable Tourism" />
      <BlurredSection>
        <TourismHero />
        <TourismLists />
        <ContactLead />
      </BlurredSection>
    </>
  )
}

export default SustainableTourism
